@import url('https://fonts.googleapis.com/css?family=Oxygen:400,300,700');
@import url('https://code.ionicframework.com/ionicons/1.4.1/css/ionicons.min.css');

/* Color and font variables */
:root {
 
  --ui-accent: #333;;
    --gradient-start: #555;
    --gradient-end: #555;
    --avatar-size: 100px;
    --profile-padding: 20px;
    --dot-container-width: 30px;
    --dot-container-height: 16px;
    --dot-height-difference: 4px;
    --font-offset: 2px;
  }
  
  /* General styles */
  html, body {
    min-height: 100%;
    font-family: 'Oxygen', sans-serif;
    font-weight: 300;
    font-size: 1em;
    background: #FFBF00;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
 
  /* Sign-in Form */
  .signin {
    display: block;
    width: 300px;
    margin: 140px auto;
    padding: var(--profile-padding);
    background-color: rgba(0,0,0, 0.1);
    border-radius: 5px;
    box-shadow: inset 1px 1px 0 0 rgba(255,255,255, 0.2), inset -1px -1px 0 0 rgba(0,0,0, 0.2);
  }
  
  /* Avatar Styling */
  .avatar {
    width: var(--avatar-size);
    height: var(--avatar-size);
    margin: 0 auto 35px;
    border: 5px solid #fff;
    border-radius: 50%;
    background-image: url('../hamster-192.ico');
    background-size: cover; /* Ensures the image covers the entire circle */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
  }
  
  
  /* Input Row */
  .inputrow {
    position: relative;
    margin-bottom: 10px;
  }
  
  .inputrow label {
    position: absolute;
    top: 12px;
    left: 10px;
    color: var(--ui-accent);
    opacity: 0.4;
    transition: opacity 300ms ease;
  }
  
  .inputrow input[type="text"],
  .inputrow input[type="password"] {
    padding: 10px 12px 10px 32px;
    width: 100%;
    border: 1px solid rgba(255,255,255, 0.5);
    background-color: rgba(255,255,255, 1);
    color: #333;
    font-size: 1em;
    font-weight: 300;
    border-radius: 5px;
    box-sizing: border-box;
    outline: none;
    transition: border-color 300ms ease;
  }
  
  .inputrow input[type="text"]:focus + label,
  .inputrow input[type="password"]:focus + label {
    opacity: 1;
  }
  
  /* Submit Button */
  input[type="submit"] {
    height: 40px;
    padding: 10px 12px;
    background-color: var(--ui-accent);
    color: #fff;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    transition: background-color 200ms ease;
  }
  
  input[type="submit"]:hover {
    background-color: #649ab2;
  }
  
  /* Checkbox Styling */
  input[type="checkbox"] {
    display: none;
  }
  
  input[type="checkbox"] + label {
    position: relative;
    padding-left: 36px;
    font-size: 0.8em;
    line-height: var(--dot-container-height);
    opacity: 0.8;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  input[type="checkbox"] + label::before,
  input[type="checkbox"] + label::after {
    content: "";
    position: absolute;
    display: block;
    border-radius: 30px;
  }
  
  input[type="checkbox"] + label::before {
    left: 0;
    width: var(--dot-container-width);
    height: var(--dot-container-height);
    background: rgba(0,0,0,0.3);
    box-shadow: inset 1px 1px 1px rgba(0,0,0,0.3);
  }
  
  input[type="checkbox"] + label::after {
    top: var(--dot-height-difference);
    left: var(--dot-height-difference);
    width: var(--dot-container-height - var(--dot-height-difference));
    height: var(--dot-container-height - var(--dot-height-difference));
    background: #fff;
    opacity: 0.3;
    transition: all 200ms ease;
  }
  
  input[type="checkbox"]:checked + label::after {
    left: calc(var(--dot-container-width) - var(--dot-container-height));
    opacity: 1;
  }
  