/* Game Controls */
.game-controls {
		/* Ball Display */
		.ball-display-vsm {
			width: 100%;
			min-width: 100%;
			max-width: 80px;
			display: flex;
			border-radius: 100%;
			position: relative;
			align-items: center;
			justify-content: center;
			margin: 0 auto;
			box-shadow: inset 0.5rem 0.5rem 1.5rem 0.25rem rgba(255, 255, 255, 0.3), inset -0.25rem -0.25rem 1.5rem 0.5rem rgba(0, 0, 0, 0.4);
	
			&::after {
				content: "";
				display: block;
				padding-bottom: 100%;
			}
	
			.content {
				text-align: center;
				border-radius: 100%;
				background: white;
				position: relative;
				min-width: 60px;
				min-width: 3vw;
				padding: 20%;
				box-shadow: inset -0.5rem -0.5rem 2rem 0 rgba(0, 0, 0, 0.16), 0 0 0 0.25rem white;
				background: radial-gradient(circle at 20% 20%, white, #efefef);
	
				.ball-content {
					justify-content: center;
					align-items: center;
					position: absolute;
					text-align: center;
					color: #222222;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					display: flex;
					flex-direction: column;
					.ball-letter,
					.ball-number {
						line-height: 0.2;
						display: block;
						width: 100%;
						
					}
					.ball-letter {
						font-size: 1rem;
						font-weight: bold;
					}
					.ball-number {
						font-size: 1.25rem;
					}
				}
	
				img {
					padding: 0.25rem;
					width: 90%;
					max-width: 100%;
				}
			}
	
			&.blue {
				background: radial-gradient(circle at 20% 20%, #0000ff, #0000ad);
				.content {
					border: 0.15rem solid #0000ff;
				}
			}
	
			&.red {
				background: radial-gradient(circle at 20% 20%, #ff0000, #910000);
				.content {
					border: 0.15rem solid #ff0000;
				}
			}
	
			&.white {
				background: radial-gradient(circle at 20% 20%, #ffffff, #b6b6b6);
				.content {
					border: 0.15rem solid #ff0000;
				}
			}
	
			&.green {
				background: radial-gradient(circle at 20% 20%, #008000, #004a00);
				.content {
					border: 0.15rem solid #008000;
				}
			}
	
			&.yellow {
				background: radial-gradient(circle at 20% 20%, #ffe52a, #f7bd00);
				.content {
					border: 0.15rem solid #f7bd00;
				}
			}
		}
		/* Ball Display */
		.ball-display-sm {
			width: 100%;
			min-width: 94px;
			max-width: 130px;
			display: flex;
			border-radius: 100%;
			position: relative;
			align-items: center;
			justify-content: center;
			margin: 0 auto;
			box-shadow: inset 0.5rem 0.5rem 1.5rem 0.25rem rgba(255, 255, 255, 0.3), inset -0.25rem -0.25rem 1.5rem 0.5rem rgba(0, 0, 0, 0.4);
	
			&::after {
				content: "";
				display: block;
				padding-bottom: 100%;
			}
	
			.content {
				text-align: center;
				border-radius: 100%;
				background: white;
				position: relative;
				min-width: 60px;
				min-width: 3vw;
				padding: 28%;
				box-shadow: inset -0.5rem -0.5rem 2rem 0 rgba(0, 0, 0, 0.16), 0 0 0 0.25rem white;
				background: radial-gradient(circle at 20% 20%, white, #efefef);
	
				.ball-content {
					justify-content: center;
					align-items: center;
					position: absolute;
					text-align: center;
					color: #222222;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					display: inline-flex;
					flex-direction: column;
					.ball-letter,
					.ball-number {
						line-height: 1;
						display: block;
						width: 100%;
					}
					.ball-letter {
						font-size: 1rem;
						font-weight: bold;
					}
					.ball-number {
						font-size: 2rem;
					}
				}
	
				img {
					padding: 0.25rem;
					width: 98%;
					max-width: 100%;
				}
			}
	
			&.blue {
				background: radial-gradient(circle at 20% 20%, #0000ff, #0000ad);
				.content {
					border: 0.15rem solid #0000ff;
				}
			}
	
			&.red {
				background: radial-gradient(circle at 20% 20%, #ff0000, #910000);
				.content {
					border: 0.15rem solid #ff0000;
				}
			}
	
			&.white {
				background: radial-gradient(circle at 20% 20%, #ffffff, #b6b6b6);
				.content {
					border: 0.15rem solid #ff0000;
				}
			}
	
			&.green {
				background: radial-gradient(circle at 20% 20%, #008000, #004a00);
				.content {
					border: 0.15rem solid #008000;
				}
			}
	
			&.yellow {
				background: radial-gradient(circle at 20% 20%, #ffe52a, #f7bd00);
				.content {
					border: 0.15rem solid #f7bd00;
				}
			}
		}
	/* Ball Display */
	.ball-display {
		width: 100%;
		min-width: 160px;
		max-width: 200px;
		display: flex;
		border-radius: 100%;
		position: relative;
		align-items: center;
		justify-content: center;
	
		box-shadow: inset 0.5rem 0.5rem 1.5rem 0.25rem rgba(255, 255, 255, 0.3), inset -0.25rem -0.25rem 1.5rem 0.5rem rgba(0, 0, 0, 0.4);

		&::after {
			content: "";
			display: block;
			padding-bottom: 100%;
		}

		.content {
			text-align: center;
			border-radius: 100%;
			background: white;
			position: relative;
			min-width: 60px;
			min-width: 3vw;
			padding: 30%;
			box-shadow: inset -0.5rem -0.5rem 2rem 0 rgba(0, 0, 0, 0.16), 0 0 0 0.25rem white;
			background: radial-gradient(circle at 20% 20%, white, #efefef);

			.ball-content {
				justify-content: center;
				align-items: center;
				position: absolute;
				text-align: center;
				color: #222222;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: inline-flex;
				flex-direction: column;
				.ball-letter,
				.ball-number {
					line-height: 1;
					display: block;
					width: 100%;
				}
				.ball-letter {
					font-size: 2rem;
					font-weight: bold;
				}
				.ball-number {
					font-size: 3.25rem;
				}
			}

			img {
				padding: 0.25rem;
				width: 90%;
				max-width: 100%;
			}
		}

		&.blue {
			background: radial-gradient(circle at 20% 20%, #0000ff, #0000ad);
			.content {
				border: 0.15rem solid #0000ff;
			}
		}

		&.red {
			background: radial-gradient(circle at 20% 20%, #ff0000, #910000);
			.content {
				border: 0.15rem solid #ff0000;
			}
		}

		&.white {
			background: radial-gradient(circle at 20% 20%, #ffffff, #b6b6b6);
			.content {
				border: 0.15rem solid #ff0000;
			}
		}

		&.green {
			background: radial-gradient(circle at 20% 20%, #008000, #004a00);
			.content {
				border: 0.15rem solid #008000;
			}
		}

		&.yellow {
			background: radial-gradient(circle at 20% 20%, #ffe52a, #f7bd00);
			.content {
				border: 0.15rem solid #f7bd00;
			}
		}
	}
	/* Previous Calls Display */
	.previous-calls {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		text-align: center;
		margin: 0 auto;
		color: #222222;
	}
	.modal .previous-calls {
		max-width: 300px;
		div {
			flex: 0 0 32px;
			margin: 5px;
		}
	}

	/* Gameplay Controls */
	.gameplay-controls {
		button {
			min-width: 150px;
			width: 80%;
			display: block;
			margin: 0.5rem auto;
		}
	}

	/* Game Settings */
	.game-settings {
		width: 100%;
		span {
			line-height: 2.5;
		}
		.select-input {
			width: 100%;
			min-width: 140px;
			max-width: 260px;
			border: 1px solid #fff;

			div[class$="-singleValue"] {
				color: #888888;
			}
			div[class$="-Input"] {
				color: white;
			}
			div[class$="-control"] {
				background: #222;
				border: none;
				color: #efefef;
				border-radius: 0;
			}
			div[class$="-menu"] * {
				color: #f0f0f0;
			}
		}
		@media screen and (max-width: 700px) {
			.row {
				flex-direction: column;
			}
		}
	}
}
