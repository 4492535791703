:root {
	--primary-color: #00487b;
	--secondary-color: #1396d3;
	--condensed-font: "Roboto Condensed", sans-serif;
	--heading-font: "Roboto Condensed", sans-serif;
	--body-font: "Roboto", sans-serif;
	--digital-font: "Digital", "Roboto Condensed", "Helvetica", cursive;
	--release-color: #5cb3ee;
	--bugfix-color: #ff8b9f;
}
