/* Variables */
$background-gradient: linear-gradient(180deg, #001f3f, #001427);
$text-color: #f0f0f0;
$primary-color: #39a0ed;
$hover-color: #1abc9c;
$input-bg: #001f3f;
$input-border: $primary-color;
$button-bg: #39a0ed;
$button-hover-bg: #1abc9c;
$button-disabled-bg: #b0b0b0;
$shadow: rgba(0, 0, 0, 0.3);

/* Body styling */
body {
  background: $background-gradient;
  
}

/* General container */
.report-container {
  display: flex; /* Flexbox layout for 30%-70% split */
  justify-content: space-between;
  align-items: flex-start; /* Align items at the top */
  min-height: 100vh;
  padding: 2rem;
  gap: 2rem; /* Space between form and table */
  color: $text-color;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  h1 {
    text-align: center;
    color: $primary-color;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
  }
}

/* Form container */
.report-form {
  width: 30%; /* 30% width */
  background: darken(#f0f0f0, 5%);
  border-radius: 0.625rem; /* 10px to rem */
  padding: 1.5rem; /* 20px to rem */
  box-shadow: 0 0.25rem 0.625rem $shadow; /* Shadow */

  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem; /* 25px to rem */

    label {
      font-size: 1.1rem; /* Increased for better readability */
      color: #001427;
      margin-bottom: 0.5rem; /* 8px to rem */
    }

    input,
    select {
      padding: 0.875rem; /* 14px to rem */
      width: 100%;
      border: 0.125rem solid $input-border; /* 2px to rem */
      border-radius: 0.5rem; /* 8px to rem */
      background-color: $input-bg;
      color: $text-color;
      font-size: 1rem;
      transition: border-color 0.3s ease;

      &:focus {
        border-color: $hover-color;
        outline: none;
      }

      &:hover {
        background-color: lighten($input-bg, 10%);
      }
    }
  }

  .submit-btn {
    padding: 0.75rem 1.5rem; /* Button size */
    background: $button-bg;
    border: none;
    border-radius: 0.5rem; /* Rounded edges */
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;

    &:hover {
      background: $button-hover-bg;
      transform: scale(1.05);
    }

    &:disabled {
      background: $button-disabled-bg;
      cursor: not-allowed;
    }
  }
}

/* Table container */
.report-table {
    width: 100%; /* Ensure table takes the full container width */
    border-collapse: collapse; /* Merge borders for a clean look */
    margin-top: 0; /* Align with form */
    border-radius: 0.5rem; /* Rounded edges */
    overflow: hidden;
  
    thead {
      background: $primary-color;
  
      th {
        padding: 0.75rem; /* Reduced padding for smaller headers */
        color: white;
        text-align: left;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.9rem; /* Slightly smaller font size */
        white-space: nowrap; /* Prevent text wrapping in headers */
      }
    }
  
    tbody {
      tr {
        border-bottom: 0.0625rem solid #ccc; /* Thin border between rows */
        transition: background 0.3s ease;
  
        &:nth-child(even) {
          background: darken($input-bg, 5%);
        }
  
        &:nth-child(odd) {
          background: lighten($input-bg, 5%);
        }
  
        &:hover {
          background: $hover-color;
          cursor: pointer;
        }
  
        td {
          padding: 0.75rem; /* Reduced padding for smaller cells */
          color: lighten($text-color, 15%);
          font-size: 0.9rem; /* Ensure consistent font size */
          white-space: nowrap; /* Prevent text wrapping in cells */
          overflow: hidden; /* Prevent content overflow */
          text-overflow: ellipsis; /* Add ellipsis if text is too long */
        }
      }
    }
  
    /* Disable horizontal scrolling */
    table-layout: fixed; /* Prevent uneven column widths */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .report-table {
      width: 100%; /* Full width on smaller screens */
      font-size: 0.85rem; /* Adjust font size for smaller screens */
  
      th,
      td {
        padding: 0.5rem; /* Reduced padding for mobile */
      }
    }
  }
  