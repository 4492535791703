#bingoTable {
    border-collapse: collapse;
    width: 250px;
    margin: 0;
    padding: 0;
    margin-top: 30px;

    th {
        background: radial-gradient(circle at 80px 80px, #fff, #fff);
        color: red;
        border: 0.5px solid #828282;
        width: 20%;
        height: 2.2rem;
        font-size: 24px;
        text-align: center;
        padding: 0;
    }

    td {
        border: 1px solid #ccc;
        width: 20%;
        height: 3rem;
        text-align: center;
        position: relative;
        background-color: #fff;
       
    }
}

.circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 45px;
    height: 45px;
    background-color: red;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}
