// Fonts -----------------------------------
@font-face {
	font-display: swap;
	font-family: "Digital";
	font-weight: normal;
	font-style: normal;
	src: url("../fonts/digital-7.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Roboto";
	font-weight: 100;
	font-style: normal;
	src: url("../fonts/Roboto-Light.woff");
}
@font-face {
	font-display: swap;
	font-family: "Roboto";
	font-weight: normal;
	font-style: normal;
	src: url("../fonts/Roboto-Regular.woff");
}
@font-face {
	font-display: swap;
	font-family: "Roboto";
	font-weight: 500;
	font-style: normal;
	src: url("../fonts/Roboto-Medium.woff");
}
@font-face {
	font-display: swap;
	font-family: "Roboto";
	font-weight: 600;
	font-style: normal;
	src: url("../fonts/Roboto-Bold.woff");
}
@font-face {
	font-display: swap;
	font-family: "Roboto";
	font-weight: 900;
	font-style: normal;
	src: url("../fonts/Roboto-Black.woff");
}
@font-face {
	font-display: swap;
	font-family: "Roboto Condensed";
	font-weight: 100;
	font-style: normal;
	src: url("../fonts/RobotoCondensed-Light.woff");
}
@font-face {
	font-display: swap;
	font-family: "Roboto Condensed";
	font-weight: normal;
	font-style: normal;
	src: url("../fonts/RobotoCondensed-Regular.woff");
}
@font-face {
	font-display: swap;
	font-family: "Roboto Condensed";
	font-weight: 900;
	font-style: normal;
	src: url("../fonts/RobotoCondensed-Bold.woff");
}
// Typography Styles ---------------
body,
html {
	font-family: var(--heading-font);
	font-size: 16px;
}

ul,
ol,
p {
	font-size: 1.25rem;
	font-family: var(--body-font);
}

ul p,
ol p {
	font-size: 0.8em;
}

h1 {
	font-size: 2rem;
}
h2 {
	font-size: 1.8rem;
}
h3 {
	font-size: 1.6rem;
}
h4 {
	font-size: 1.4rem;
}
h5 {
	font-size: 1.2rem;
}
h6 {
	font-size: 1rem;
	margin: 0;
	color: var(--secondary-color);
}
