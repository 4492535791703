
  $background-gradient: linear-gradient(180deg, #001f3f, #001427);
  $text-color: #f0f0f0;
  /* Body styling */
body {
    background: $background-gradient;
    color: $text-color;
  }
  
  .prev-g{
    display: flex;
  }
  .prev-g button{
    background: #0cb528;
    border: none;
    border-radius: 0.5rem; /* Rounded edges */
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
    height: 35px;
    margin: 2rem;
  }
.register-game {
    display: flex;
    flex-direction: column;
   
    gap: 20px; /* Space between form groups */
    background: $background-gradient;
    color: $text-color;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
.row-inputs{
    display: flex;
    flex-direction: row;
   
    width: 100%;
    
}
    .form-group {
        position: relative;
        width: 24%; /* Adjust width as needed */
        margin-left: 0.4%;
        border: 2px solid #FFBF00;; /* Border for the rectangle */
        border-radius: 8px; /* Rounded corners */
        background-color: #fff; /* Background color */
        padding: 20px; /* Padding inside the rectangle */

        .form-label {
            position: absolute;
            top: -5px; /* Position label above the select */
            left: 20px; /* Position label to the left */
            font-size: 28px; /* Font size for the label */
            font-weight: bold; /* Bold text */
            color: #333; /* Text color */
        }

        .form-control {
            width: 100%; /* Full width for the select */
            padding: 10px; /* Padding for better touch target */
            border: none; /* Remove default border */
            border-radius: 5px; /* Rounded corners */
            background-color: #FFBF00;; /* Light background color */
            color: #333; /* Dark text color */
            font-size: 36px; /* Font size for options */
            cursor: pointer; /* Pointer on hover */
            transition: background-color 0.3s; /* Smooth transition for background */

            &:focus {
                outline: none; /* Remove default outline */
                background-color: #e9e9e9; /* Change background on focus */
            }

            option {
                padding: 10px; /* Padding for options */
            }
        }
    }

    &__numcolumn {
        display: flex;
        flex-direction: column;
    }

    &__numrow {
        display: flex;
        justify-content: space-around;
    }

    &__balls-line {
        hr {
            width: 60%;
            background: #bc4307;
        }
    }

    &__numbox {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background: radial-gradient(circle,#FF7F50 0%, #FFBF00 0%, #FFBF00 8%,#ff5733 47%,  #FFBF00  100%);
        box-shadow: 0px 5px 6px rgba(50, 50, 50, 0.55);
        color: #444;
        text-align: center;
        font-weight: normal;
        font-size: 1.4rem;
        margin: 2px;
        opacity: 0.8;
        border: 2px solid #ffd700;
        cursor: pointer;

        &:hover {
            background: radial-gradient(circle, #66cc66, #28a745);
            color: white;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
    }

    &__balls {
        width: 98%;
    }

    &__prepare-sleep {
        position: absolute;
        top: 0;
        right: 0;

        h3 {
            background: #09b517;
            text-transform: uppercase;
            padding: 4px;
            width: 180px;
            text-align: center;
            color: white;
            border-radius: 2px;
            cursor: pointer;
        }
    }

    &__regcard {
        display: none;
        width: 35%;
        height: 100vh;
    }

    &__custom-cell {
        width: 3rem; /* Adjusted width */
        height: 3rem; /* Adjusted height */
        border-radius: 50%;
        background: radial-gradient(circle, #66cc66, #28a745);
        color: white;
        text-align: center;
        font-weight: normal;
        font-size: 1.4rem;
        margin: 2px;
        border: 2px solid #ffd700;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 auto;
    }

    &__next {
        display: flex;
        flex-wrap: wrap;
        width: 80%;
        margin: 0 auto;
        justify-content: center;

        button {
            height: 3.5rem;
            width: 3.5rem;
            padding: 1px;
            margin: 0 4px;
            border: 1px solid #FFBF00;
            background-color: #e9e9e9;
            color: #333;
            cursor: pointer;

            &:hover {
                background-color: #e9e9e9;
            }
        }
    }

    &__box {
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 50%;
        background: radial-gradient(circle,#FF7F50 0%, #FFBF00 0%, #FFBF00 8%,#ff5733 47%,  #FFBF00  100%);
        box-shadow: 0px 5px 6px rgba(50, 50, 50, 0.55);      color: #fff;
        text-align: center;
        font-weight: 500;
        font-size: 2.5rem;
        margin: 0.1rem;
        padding-top: 0.8rem;
        border: 2px solid #444;
        cursor: pointer;
        color: #333;

        &.snum {
            background: radial-gradient(circle, #66cc66, #28a745);
            color: white;
        }
    }

    /* Media query for mobile devices */
    @media only screen and (max-width: 600px) {
        .row-inputs{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            
        }
        .form-group {
            position: relative;
            width: 90%; /* Adjust width as needed */
            margin-left: 5%;
      
        }    
        &__next {
            button:nth-of-type(1) {
                display: block;
                position: absolute;
                top: 50%;
                left: 1%;
                transform: translateX(-50%);
                background-color: #FF7F50;
                color: #333;
            }
            button:nth-last-child(1) {
                display: block;
                position: absolute;
                top: 50%;
                right: 1%;
                transform: translateX(50%);
                background-color: #FF7F50;
                color: #333;
            }
            button:not(:first-child):not(:last-child) {
                display: none;
            }
        }

        &__content {
            flex-direction: column; 
            align-items: center;
        }

        &__balls {
            width: 100%;
        }

        &__regcard {
            width: 100%;
        }

        &__numbox {
            width: 5rem;
            height: 5rem;
            font-weight: normal;
            font-size: 4rem;
            opacity: 0.8;
        }
    }
}
