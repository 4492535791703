@media print {
  @page {
    margin: 0.15in 0.15in 0.15in 0.15in !important;
    orientation: landscape;
  }
  @page:blank {
    display: none;
  }
  div {
    page-break-inside: avoid;
    break-inside: avoid;
    -webkit-break-inside: avoid;
  }
  header, footer, .no-print {
    display: none;
  }
  body {
    -webkit-print-color-adjust: exact !important;
  }
  .card-block {
    max-width: unset;
    margin: 1rem auto;
  }
  .pale-gray-bg {
    background: white;
  }
  .print-bw {
     filter: Gray();
     filter: url('#grayscale'); 
     -webkit-filter: grayscale(100%); 
     filter: grayscale(100%); 
  }
  .print-two {
    .card {
      .col {
        .freespace {
          padding: 0.58rem 0 !important;
        }
      }
    }
  }
  .print-four {
    .card {
      .col {
        .card-letter,
        .card-number {
          font-size: 2.5rem !important; 
          line-height: 1 !important;
        }
        .freespace {
          padding: 0.25rem 0;
          span {
            font-size: .74rem !important;
            line-height: 1;
          }
        }
      }
    }
  }
  .print-six {
    .card {
      width: 30%;
      .col {
        .card-letter,
        .card-number {
          font-size: 2.5rem !important; 
          line-height: 1 !important;
        }
        .freespace {
          padding: 0.25rem 0;
          span {
            font-size: 1rem;
            line-height: 1;
          }
        }
      }
    }
  }
}